import React from "react";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CardNumberField from "../../PaymentMethodFields/CardNumberField";
import ExpiryField from "../../PaymentMethodFields/ExpiryField";
import CVCField from "../../PaymentMethodFields/CVCField";
import NeedHelpButton from "@app.automotus.io/components/scenes/SignUp/NeedHelpButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { CardMethodFormFields } from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm/CardMethodFormFields";
import ZipCodeField from "@app.automotus.io/components/forms/PaymentMethodFields/ZipCodeField";
import { appName } from "common/constants";

export const CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES = {
  cardNumber: "",
  expiry: "",
  cvc: "",
  zipCode: "",
} as const;

export const cardMethodFormValidationSchema = yup.object().shape({
  cardNumber: CardNumberField.validationSchema,
  expiry: ExpiryField.validationSchema,
  cvc: CVCField.validationSchema,
  zipCode: ZipCodeField.validationSchema,
});

export const CardMethodForm: React.FC<CardMethodFormProps> = ({ loading = false }: CardMethodFormProps) => {
  return (
    <Box>
      <CardMethodFormFields loading={loading} />
      <Grid item xs={12} mt={5}>
        <LoadingButton variant="contained" fullWidth id="form-submit" type="submit" loading={loading}>
          Save Payment Method
        </LoadingButton>
      </Grid>
      {appName !== "curbsuite.io" && (
        <Grid item xs={12}>
          <NeedHelpButton sx={{ mt: 2 }} fullWidth />
        </Grid>
      )}
    </Box>
  );
};

export interface CardMethodFormProps {
  loading?: boolean;
  onSubmit: (values: ValidatedPaymentMethodCardFormValues) => Promise<void>;
}

type RequiredCardInfoFormValues = "cardNumber" | "expiry" | "cvc";

export interface PaymentMethodCardFormValues {
  cardNumber: string;
  expiry: string;
  cvc: string;
  zipCode: string;
}

export type ValidatedPaymentMethodCardFormValues = {
  [P in keyof PaymentMethodCardFormValues]: P extends RequiredCardInfoFormValues
    ? NonNullable<PaymentMethodCardFormValues[P]>
    : PaymentMethodCardFormValues[P];
};

export default CardMethodForm;
