import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { PaymentMethod } from "common/graphql";
import { SavedPaymentMethodComponentProps } from "../SavedPaymentMethod";

/**
 * Renders a payment card saved to a user's stripe account.
 */
export const SavedPaymentAch: React.FC<SavedPaymentAchProps> = ({ loading, paymentMethod }) => {
  const theme = useTheme();

  const { maskedNumber } = paymentMethod || {};

  return (
    <Box
      sx={{ border: 1, borderRadius: 1, borderColor: "grey.400", height: theme.spacing(6) }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ ml: 2 }} display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="body4">
          {loading || !maskedNumber ? <Skeleton width="8.57em" /> : `●●●●●●●● ${maskedNumber}`}
        </Typography>
      </Box>
    </Box>
  );
};

/** Props passed to initialize a {@link SavedPaymentAch} component */
export type SavedPaymentAchProps = SavedPaymentMethodComponentProps<PaymentMethod>;

export default SavedPaymentAch;
