import React from "react";
import { useField } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export const AccountTypeField = Object.assign(
  ({ name = "accountType", id = "account-type", label = "Account Type", disabled = false }: AccountTypeFieldProps) => {
    const [field] = useField(name);

    return (
      <Box>
        <FormControl>
          <FormLabel id={id}>{label}</FormLabel>
          <RadioGroup aria-labelledby={id} {...field} name={name}>
            <FormControlLabel value="checking" control={<Radio />} label="Checking" disabled={disabled} />
            <FormControlLabel
              value="businessChecking"
              control={<Radio />}
              label="Business Checking"
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  },
  {
    defaultInitialValue: "checking",
    validationSchema: yup
      .string()
      .required("Required field")
      .oneOf(["checking", "businessChecking"], "Invalid account type"),
  },
);

export interface AccountTypeFieldProps {
  name?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
}

export default AccountTypeField;
