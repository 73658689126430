import React, { forwardRef, useState } from "react";
import {
  useActivePayee,
  useActiveWallet,
  useApplePay,
  useIsMobile,
  usePaymentsHelpers,
  useUserProfile,
} from "@app.automotus.io/components/hooks";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { CardMethodFormFields } from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm/CardMethodFormFields";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import {
  CREATE_PAYMENT_METHOD,
  CreatePaymentMethodData,
  CreatePaymentMethodVars,
  GET_USER_PROFILE,
  LOAD_WALLET,
  LoadWalletData,
  LoadWalletVars,
} from "common/graphql";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import { Form, Formik, FormikHelpers } from "formik";
import Grid from "@mui/material/Grid";
import GooglePayButton from "@app.automotus.io/components/forms/PaymentMethodFields/GooglePayButton";
import ApplePayButton from "@app.automotus.io/components/forms/PaymentMethodFields/ApplePayButton";
import Divider from "@mui/material/Divider";
import {
  CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES,
  cardMethodFormValidationSchema,
} from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import {
  BANK_METHOD_FORM_INITIAL_VALUES,
  BankMethodFormFields,
  bankMethodFormValidationSchema,
} from "@app.automotus.io/components/forms/PaymentMethodForm/BankMethodForm/BankMethodFormFields";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const SIGNUP_HEADER_HEIGHT_DESKTOP = 70;
const SIGNUP_HEADER_HEIGHT_MOBILE = 62;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CurbPassPaymentMethodSetup = forwardRef<HTMLDivElement, CurbPassSetupPaymentProps>(
  ({ isOpen, onClose, paymentAmount, disabled, invoiceId, canceled, isAchEnabled }, ref) => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    // TODO: handle error
    const [{ addPaymentMethod, payInvoice }, { clientLoading }] = usePaymentsHelpers();
    const { activePayee } = useActivePayee();
    const { wallet } = useActiveWallet();
    const [loadWallet] = useMutation<LoadWalletData, LoadWalletVars>(LOAD_WALLET);
    const { publishSnackbarMessage } = useSnackPackContext();
    const { performApplePayPayment } = useApplePay();
    const [createPaymentMethod] = useMutation<CreatePaymentMethodData, CreatePaymentMethodVars>(CREATE_PAYMENT_METHOD);
    const [finishOnboarding] = useMutation<FinishOnboardingData>(FINISH_ONBOARDING);
    const { userProfile } = useUserProfile();
    const location = useLocation();
    const navigate = useNavigate();
    const [ratesAndHoursOpen, setRatesAndHoursOpen] = React.useState<boolean>(false);
    const isTiny = useMediaQuery(theme.breakpoints.down("tiny"));

    type FormSettings = CardFormSettings | BankFormSettings;

    interface CardFormSettings {
      paymentMethodType: "card";
      initialValues: typeof CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES & { paymentMethodType: "card" };
      validationSchema: typeof cardMethodFormValidationSchema;
    }

    interface BankFormSettings {
      paymentMethodType: "ach";
      initialValues: typeof BANK_METHOD_FORM_INITIAL_VALUES & { paymentMethodType: "ach" };
      validationSchema: typeof bankMethodFormValidationSchema;
    }

    const [formSettings, setFormSettings] = useState<FormSettings>({
      paymentMethodType: "card",
      initialValues: { ...CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES, paymentMethodType: "card" },
      validationSchema: cardMethodFormValidationSchema,
    });

    const handleChangePaymentMethodType = (
      event: React.MouseEvent<HTMLElement>,
      newPaymentMethodType: "card" | "ach",
    ) => {
      if (newPaymentMethodType === "card") {
        setFormSettings({
          paymentMethodType: "card",
          initialValues: { ...CARD_METHOD_FORM_DEFAULT_INITIAL_VALUES, paymentMethodType: "card" },
          validationSchema: cardMethodFormValidationSchema,
        });
      } else {
        setFormSettings({
          paymentMethodType: "ach",
          initialValues: { ...BANK_METHOD_FORM_INITIAL_VALUES, paymentMethodType: "ach" },
          validationSchema: bankMethodFormValidationSchema,
        });
      }
    };

    async function handleSubmit<T extends FormSettings["initialValues"]>(
      values: T,
      { setSubmitting }: FormikHelpers<T>,
    ): Promise<void> {
      console.log(values);

      if (!activePayee || !userProfile) {
        return;
      }

      let paymentMethodId: string;
      try {
        const paymentMethod = await addPaymentMethod({
          ...(values.paymentMethodType === "ach"
            ? { type: "bank", bank: { ...values } }
            : { type: "card", card: { ...values } }),
          makeDefault: true,
        });

        paymentMethodId = paymentMethod.paymentMethodId;
      } catch (err) {
        console.error("failed to add payment method", err);
        publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_METHOD_SETUP_FAILED);
        return;
      }

      // TODO: handle declined payments

      // If we want a non-zero wallet balance, fill the wallet.
      if (!userProfile.account.isFreeTrial) {
        try {
          await loadWallet({
            variables: { payeeAccountId: activePayee.payeeAccountId },
            refetchQueries: [GET_USER_PROFILE],
          });
        } catch (err) {
          console.error("failed to load wallet", err);
          publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_METHOD_SETUP_FAILED);
          return;
        }
      }

      if (invoiceId) {
        try {
          await payInvoice({ invoiceId, paymentMethodId });
        } catch (err) {
          console.error("failed to pay invoice", err);
          publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
          return;
        }
      }

      finishOnboarding().catch((err) => {
        console.error(err);
      });

      const isPaymentMade = invoiceId || !userProfile.account.isFreeTrial;
      publishSnackbarMessage(
        isPaymentMade
          ? SNACKBAR_MSGS.DIGITAL_WALLET_CREATED_SUCCESS
          : SNACKBAR_MSGS.CURBPASS_FREE_TRIAL_CREATED_SUCCESS,
      );
      setSubmitting(false);

      navigate("/signup/finish", { state: location.state });
    }

    const handleClickGooglePay = () => {
      console.log("Google Pay Clicked");
    };

    const handleClickApplePay = () => {
      performApplePayPayment(
        {
          countryCode: "US",
          currencyCode: "USD",
          label: "CurbPass Setup",
          amount: formatCurrency(wallet?.desiredWalletBalance || 0),
        },
        async (session, event) => {
          console.log(JSON.stringify(event.payment));
          try {
            await createPaymentMethod({
              variables: {
                payeeAccountId: activePayee?.payeeAccountId || "",
                isDefault: true,
                gatewayAccess: {
                  opaqueData: {
                    dataDescriptor: "COMMON.APPLE.INAPP.PAYMENT",
                    dataValue: Buffer.from(JSON.stringify(event.payment.token.paymentData)).toString("base64"),
                  },
                },
                // TODO: make sure billing contact is present... what if it's not?
                billingZip: event.payment.billingContact?.postalCode || "",
              },
            });
          } catch (err) {
            console.error("failed to create payment method", err);
            session.abort();
          }
        },
        (session, event) => {
          console.log("payment canceled", event);
        },
      );
    };

    return (
      <Formik
        initialValues={formSettings.initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSettings.validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Container
            isOpen={isOpen}
            onClose={onClose}
            isFreeTrial={!!userProfile?.account?.isFreeTrial}
            paymentAmount={paymentAmount}
          >
            <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              <Box sx={{ mt: 3 }} ref={ref}>
                <Typography sx={{ typography: { xs: "h4bxs", tiny: "h4bsmall", md: "h4b" } }}>
                  Payment Method
                </Typography>
                <Typography sx={{ mb: 3 }} variant="body1">
                  Your payment method will be saved.
                </Typography>
                {isAchEnabled && (
                  <ToggleButtonGroup
                    color="primary"
                    value={formSettings.paymentMethodType}
                    exclusive
                    aria-label="payment method type"
                    onChange={handleChangePaymentMethodType}
                    fullWidth
                  >
                    <ToggleButton value="card" aria-label="credit card">
                      Card
                    </ToggleButton>
                    <ToggleButton value="ach" aria-label="bank account">
                      ACH
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {MOBILE_PAYMENTS_ENABLED && (
                  <>
                    <Grid container justifyContent="space-between" columnSpacing={2}>
                      <Grid item xs={6}>
                        <GooglePayButton disabled={disabled} onClick={handleClickGooglePay} />
                      </Grid>
                      <Grid item xs={6}>
                        <ApplePayButton disabled={disabled} onClick={handleClickApplePay} />
                      </Grid>
                    </Grid>
                    <Box sx={{ my: 3 }}>
                      <Divider>
                        <Typography variant={"body1"} sx={{ color: theme.palette.text.secondary }}>
                          or
                        </Typography>
                      </Divider>
                    </Box>
                  </>
                )}
                <Box sx={{ mt: 2 }}>
                  {formSettings.paymentMethodType === "ach" ? (
                    <BankMethodFormFields loading={clientLoading} disabled={disabled} />
                  ) : (
                    <CardMethodFormFields loading={clientLoading} disabled={disabled} />
                  )}
                </Box>
                {canceled && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    You need to set up a payment method before proceeding
                  </Alert>
                )}
                <Grid item xs={12} sm={12}>
                  <LoadingButton
                    sx={{ mt: 3, textTransform: "unset" }}
                    size={isTiny ? "small" : isMobile ? "medium" : "large"}
                    variant="contained"
                    fullWidth
                    id="form-submit"
                    type="submit"
                    disabled={disabled}
                    loading={clientLoading || props.isSubmitting}
                  >
                    {userProfile?.account?.isFreeTrial ? "Save Payment Method" : "Pay"}
                  </LoadingButton>
                </Grid>
                <PartnerLandingPageParkingRatesAndHoursModal
                  open={ratesAndHoursOpen}
                  onClose={() => setRatesAndHoursOpen(false)}
                  payeeAccountId={activePayee?.payeeAccountId || ""}
                />
              </Box>
            </Form>
          </Container>
        )}
      </Formik>
    );
  },
);

const Container: React.FC<ContainerProps> = ({ isOpen, onClose, children, isFreeTrial, paymentAmount }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTiny = useMediaQuery(theme.breakpoints.down("tiny"));
  const appbarHeight = isTiny ? SIGNUP_HEADER_HEIGHT_MOBILE : SIGNUP_HEADER_HEIGHT_DESKTOP;

  if (isMobile) {
    return (
      <Dialog
        BackdropProps={{ invisible: true }}
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            mt: `${appbarHeight + 32}px !important`,
            maxWidth: "100%",
            width: "100%",
            mx: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            mb: 0,
          },
        }}
        scroll={"paper"}
      >
        <AppBar
          sx={{
            height: "52px",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
            <Box>
              <Typography variant="body3" sx={{ fontWeight: 500 }}>
                {isFreeTrial ? "Add a Card" : `Pay $${formatCurrency(paymentAmount)}`}
              </Typography>
            </Box>
            <Box>
              <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                <Box
                  sx={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100px",
                    backgroundColor: "rgba(0, 0, 0, 0.135)",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "14px" }} />
                </Box>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ mb: 1.25, px: 3, pt: 0 }}>{children}</DialogContent>
      </Dialog>
    );
  }

  return <Box sx={{ display: isOpen ? undefined : "none" }}>{children}</Box>;
};

interface ContainerProps {
  isOpen: boolean;
  isFreeTrial: boolean;
  paymentAmount: number;
  onClose: () => void;
}

export interface CurbPassSetupPaymentProps {
  isOpen: boolean;
  paymentAmount: number;
  onClose: () => void;
  invoiceId?: string;
  disabled: boolean;
  canceled?: boolean;
  isAchEnabled?: boolean;
}

const MOBILE_PAYMENTS_ENABLED = false;

type RequiredCardInfoFormValues = "cardNumber" | "expiry" | "cvc";

export interface DigitalWalletInfoFormValues {
  cardNumber: string;
  expiry: string;
  cvc: string;
  zipCode: string;
}

export type ValidatedDigitalWalletInfoFormValues = {
  [P in keyof DigitalWalletInfoFormValues]: P extends RequiredCardInfoFormValues
    ? NonNullable<DigitalWalletInfoFormValues[P]>
    : DigitalWalletInfoFormValues[P];
};

const FINISH_ONBOARDING = gql`
  mutation FinishOnboarding {
    finishOnboarding: finish_onboarding {
      finishedAt: finished_at
    }
  }
`;

interface FinishOnboardingData {
  finishOnboarding: {
    finishedAt: string;
  };
}

export default CurbPassPaymentMethodSetup;
