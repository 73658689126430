import { gql } from "@apollo/client";
import { CreditCardBrandValue } from "common/adaptors";

export const paymentMethodFragment = gql`
  fragment paymentMethodFragment on financial_payment_method {
    id
    payeeAccountId: payee_account_id
    maskedNumber: masked_number
    isDefault: is_default
    postalCode: postal_code
    type: payment_method_type
    paymentMethodIssuer: payment_method_issuer
  }
`;

export interface PaymentMethod {
  id: string;
  payeeAccountId: string;
  maskedNumber: string;
  isDefault: boolean;
  postalCode: string;
  type: PaymentMethodType;
  paymentMethodIssuer: CreditCardBrandValue;
}

export type PaymentMethodType = "credit_card" | "check" | "wallet" | "ach";
