import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useIsMobile, useTermsOfService, useActivePayee } from "@app.automotus.io/components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import SelectAmount, { LastTouchedStatus } from "./SelectAmount";
import DiscountComponent from "./DiscountComponent";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  CREATE_WALLET,
  CreateWalletData,
  CreateWalletVars,
  GET_USER_PROFILE,
  GET_WALLET_CONFIG_WITH_PAYEE,
  GET_WALLET_WITH_PAYEE,
  GetWalletConfigWithPayeeData,
  GetWalletConfigWithPayeeVars,
} from "common/graphql";
import { OnboardingStatus } from "@app.automotus.io/components/common";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import { CurbPassPaymentMethodSetup } from "@app.automotus.io/components/scenes/SignUp/CurbPassSetup/CurbPassPaymentMethodSetup";
import SignUpTermsOfServiceCheckbox from "@app.automotus.io/components/SignUp/SignUpTermsOfServiceCheckbox";
import AirportTermsOfServiceDialog from "@app.automotus.io/components/AirportTermsOfServiceDialog";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import HowItWorksSteps from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/HowItWorksSteps";

export const DEFAULT_AMOUNT = 1500;
const MINIMUM_AMOUNT = 500;
const MAXIMUM_AMOUNT = 100000;

export const CurbPassSetup: React.FC = () => {
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const { userProfile, loading: userProfileLoading } = useUserProfile();
  const [selectedAmount, setAmount] = useState<number>(DEFAULT_AMOUNT);
  const [paymentFormOpen, setPaymentFormOpen] = useState<boolean>(false);
  const [airportTosDialogOpen, setAirportTosDialogOpen] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true);
  const theme = useTheme();
  const SPACING = 3;
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const { activePayee } = useActivePayee();
  const [getWalletConfig, { data: walletConfigData, loading: walletConfigLoading }] = useLazyQuery<
    GetWalletConfigWithPayeeData,
    GetWalletConfigWithPayeeVars
  >(GET_WALLET_CONFIG_WITH_PAYEE);
  const { publishSnackbarMessage } = useSnackPackContext();
  const {
    accept: acceptTermsOfService,
    isAccepted: isTermsOfServiceAccepted,
    loading: termsOfServiceLoading,
  } = useTermsOfService({
    onError: (op, err) => {
      console.error(err);
      publishSnackbarMessage({ message: `Failed to ${op} Terms of Service`, severity: "error" });
    },
  });

  const activePayeeAccountId = activePayee?.payeeAccountId;
  useEffect(() => {
    if (activePayeeAccountId) {
      getWalletConfig({
        variables: {
          payeeAccountId: activePayeeAccountId,
        },
      });
    }
  }, [activePayeeAccountId, getWalletConfig]);

  const isAirportAccount =
    activePayeeAccountId && activePayeeAccountId === process.env.REACT_APP_GSP_AIRPORT_ACCOUNT_ID;

  useEffect(() => {
    const isTermsOfServiceNotYetAccepted = !termsOfServiceLoading && !isTermsOfServiceAccepted;
    if (isTermsOfServiceNotYetAccepted && isAirportAccount) {
      setAirportTosDialogOpen(true);
    }
  }, [isAirportAccount, termsOfServiceLoading, isTermsOfServiceAccepted]);

  const [createWallet, { loading: createWalletLoading }] = useMutation<CreateWalletData, CreateWalletVars>(
    CREATE_WALLET,
    { refetchQueries: [GET_WALLET_WITH_PAYEE, GET_WALLET_CONFIG_WITH_PAYEE, GET_USER_PROFILE] },
  );
  useEffect(() => {
    if (
      !walletConfigLoading &&
      !userProfileLoading &&
      walletConfigData?.wallet &&
      userProfile?.account &&
      !createWalletLoading
    ) {
      setAmount(userProfile.account.isFreeTrial ? 0 : walletConfigData.wallet.desiredWalletBalance);
    }
  }, [walletConfigData, userProfile, walletConfigLoading, userProfileLoading, createWalletLoading]);

  const navigate = useNavigate();

  const handleClosePaymentForm = () => {
    setPaymentFormOpen(false);
  };

  const updateSettings = useCallback(
    async (selectedWalletAmount: number) => {
      if (activePayeeAccountId) {
        const isFreeTrial = selectedWalletAmount === 0;
        const desiredBalance = isFreeTrial ? 1500 : selectedWalletAmount;
        const minimumBalance = desiredBalance * 0.25;

        try {
          await createWallet({
            variables: {
              payeeAccountId: activePayeeAccountId,
              desiredBalance,
              minimumBalance,
              isFreeTrial,
            },
          });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [createWallet, activePayeeAccountId],
  );

  const handleAddPayment = async () => {
    await updateSettings(selectedAmount);
    setPaymentFormOpen(true);
    setTimeout(() => {
      paymentFormRef?.current?.scrollIntoView();
    }, 0);
  };

  const handleChangeAmount = (value: number, type: number) => {
    const newAmount = value;
    setAmount(newAmount);
    setIsValidAmount(type === LastTouchedStatus.Option || (newAmount >= MINIMUM_AMOUNT && newAmount <= MAXIMUM_AMOUNT));
    if (paymentFormOpen) {
      updateSettings(newAmount);
    }
  };

  const handleAgreeToAirportTos = async () => {
    await acceptTermsOfService();
    setAirportTosDialogOpen(false);
  };

  const onboardingStatusValue = userProfile?.onboardingStatus ?? "profile";
  const isEditable = OnboardingStatus.isBefore(onboardingStatusValue, OnboardingStatus.PAYMENT);

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Box mb={SPACING}>
        <Typography variant={isDesktop ? "h2bxs" : isSmall ? "h4bxs" : "h4bsmall"}>
          Setup <span style={{ color: theme.palette.primary.main }}>CurbPass</span>
        </Typography>
        {isAirportAccount ? (
          <Box sx={{ mt: 4 }}>
            <HowItWorksSteps variant="airport" />
          </Box>
        ) : (
          <Typography variant="body2">
            We’ll auto refill your CurbPass when it gets low. You can request a full refund anytime!
          </Typography>
        )}
      </Box>
      <fieldset disabled={!isEditable} style={{ border: 0, padding: 0, margin: 0 }}>
        {!isAirportAccount && (
          <>
            <SelectAmount
              disabled={!isEditable}
              amount={selectedAmount}
              minAmount={MINIMUM_AMOUNT}
              maxAmount={MAXIMUM_AMOUNT}
              onChange={handleChangeAmount}
            />
            <DiscountComponent
              title="Save 75% with CurbPass"
              description="Drivers with CurbPass save up to 75% in parking fees."
            />
          </>
        )}
        <Box sx={{ mt: 3 }}>
          {!isAirportAccount && (
            <Box>
              <Grid container direction="row" alignItems="left" rowSpacing={1} sx={{ pl: 1 }}>
                <Grid item xs={12}>
                  <SignUpTermsOfServiceCheckbox
                    linkTo={isMobile ? "/signup/payment" : "/signup/terms-of-service"}
                    linkState={{ sourceRoute: "/signup/payment" }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {!isMobile && paymentFormOpen && <Divider sx={{ mt: 3 }} />}
          {(isMobile || !paymentFormOpen) && (
            <Stack sx={{ mt: 1 }} spacing={2}>
              <LoadingButton
                id="add-payment-method"
                disabled={!userProfile?.tosAcceptedAt || !isValidAmount}
                onClick={handleAddPayment}
                variant="contained"
                fullWidth
                loading={createWalletLoading}
                size={isSmall ? "small" : "large"}
                sx={{
                  textTransform: "unset",
                  py: isMobile ? 0.75 : 1,
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                }}
              >
                Add Payment Method
              </LoadingButton>
              <Button
                onClick={() => navigate("/signup/vehicle")}
                variant={"outlined"}
                fullWidth
                sx={{
                  textTransform: "unset",
                  py: isMobile ? 0.75 : 1,
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 400,
                }}
              >
                Back
              </Button>
            </Stack>
          )}
          <CurbPassPaymentMethodSetup
            isOpen={paymentFormOpen}
            paymentAmount={selectedAmount}
            onClose={handleClosePaymentForm}
            disabled={!(isValidAmount && !!userProfile?.tosAcceptedAt)}
            ref={paymentFormRef}
            isAchEnabled={!!isAirportAccount}
          />
        </Box>
      </fieldset>
      <AirportTermsOfServiceDialog
        open={airportTosDialogOpen}
        onClickAgree={handleAgreeToAirportTos}
        LinkProps={{
          href: `${process.env.REACT_APP_ASSET_ENDPOINT}/documents/gsp-airport-terms-and-conditions.pdf`,
          target: "_blank",
        }}
      />
    </Box>
  );
};

export default CurbPassSetup;
