import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const AccountNumberField = Object.assign(
  ({
    name = "accountNumber",
    id = "account-number",
    label = "Account Number",
    placeholder = "Account Number",
    required = true,
    fullWidth = true,
    ...rest
  }: AccountNumberFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        const onlyNums = event.target.value.substr(0, 17).replace(/[^0-9]/g, "");
        setValue(onlyNums);
      },
      [setValue],
    );

    return (
      <Box>
        <Typography variant="body3">{label}</Typography>
        <TextField
          InputLabelProps={{ shrink: false }}
          {...rest}
          {...{ id, label, placeholder, required, fullWidth }}
          label=""
          meta={meta}
          form={form}
          field={{
            ...field,
            onChange: handleChange,
          }}
          sx={{ mt: 1, mb: 0.5 }}
        />
        <Typography variant="body4" color="rgba(0, 0, 0, 0.6)" sx={{ ml: 2 }}>
          Your bank account must be a checking account
        </Typography>
      </Box>
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup.string().required("Required field").max(17, "17 numbers maximum").min(5, "5 numbers minimum"),
  },
);

export interface AccountNumberFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default AccountNumberField;
