import React from "react";
import Grid from "@mui/material/Grid";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { RoutingNumberField } from "@app.automotus.io/components/forms/PaymentMethodFields/RoutingNumberField/RoutingNumberField";
import { AccountNumberField } from "@app.automotus.io/components/forms/PaymentMethodFields/AccountNumberField/AccountNumberField";
import NameOnAccountField from "../../PaymentMethodFields/NameOnAccountField";
import AccountTypeField from "../../PaymentMethodFields/AccountTypeField";
import * as yup from "yup";

export const BANK_METHOD_FORM_INITIAL_VALUES = {
  accountNumber: "",
  routingNumber: "",
  nameOnAccount: "",
  accountType: "checking" as "checking" | "businessChecking",
} as const;

export const bankMethodFormValidationSchema = yup.object().shape({
  accountNumber: AccountNumberField.validationSchema,
  routingNumber: RoutingNumberField.validationSchema,
  nameOnAccount: NameOnAccountField.validationSchema,
});

export const BankMethodFormFields: React.FC<BankMethodFormFieldsProps> = ({ loading, disabled = false }) => {
  return (
    <Grid container justifyContent="space-between" rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} sm={12}>
        <LoadingSkeleton loading={loading} width="100%">
          <AccountTypeField disabled={disabled} />
        </LoadingSkeleton>
      </Grid>
      <Grid item xs={12} sm={12}>
        <LoadingSkeleton loading={loading} width="100%">
          <RoutingNumberField disabled={disabled} />
        </LoadingSkeleton>
      </Grid>
      <Grid item xs={12} sm={12}>
        <LoadingSkeleton loading={loading} width="100%">
          <AccountNumberField disabled={disabled} />
        </LoadingSkeleton>
      </Grid>
      <Grid item xs={12} sm={12}>
        <LoadingSkeleton loading={loading} width="100%">
          <NameOnAccountField disabled={disabled} />
        </LoadingSkeleton>
      </Grid>
    </Grid>
  );
};

export interface BankMethodFormFieldsProps {
  loading: boolean;
  disabled?: boolean;
}
