import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const NameOnAccountField = Object.assign(
  ({
    name = "nameOnAccount",
    id = "name-on-account",
    label = "Name on Account",
    placeholder = "Name on Account",
    required = true,
    fullWidth = true,
    ...rest
  }: NameOnAccountFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        setValue(event.target.value);
      },
      [setValue],
    );

    return (
      <Box>
        <Typography variant="body3">{label}</Typography>
        <TextField
          InputLabelProps={{ shrink: false }}
          {...rest}
          {...{ id, label, placeholder, required, fullWidth }}
          label=""
          meta={meta}
          form={form}
          field={{
            ...field,
            onChange: handleChange,
          }}
          sx={{ mt: 1, mb: 0.5 }}
        />
      </Box>
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup.string().required("Required field").max(22, "22 character maximum"),
  },
);

export interface NameOnAccountFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
}

export default NameOnAccountField;
